/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="mt--40 pb--80 pt--0" name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--0" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"Presliste"}>
              </Title>

              <Image className="--left pb--02 pl--02 pr--02 pt--02" alt={""} src={"https://cdn.swbpg.com/o/11591/f54a559b8f5e4d9086b55732bbd82975.svg"} svg={false} href={"/de/"} sizes={"100vw"} style={{"maxWidth":36}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" anim={null} name={"xi6rhmztau"}>
          
          <ColumnWrap className="column__flex el--2 pb--0 pt--0 flex--stretch" anim={null} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Ganztägiges Hochzeitsfotografieren / 12 Std."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">Fotografieren von Hochzeitsgästen und der Zeremonie</span>"}>
              </Text>

              <Text className="text-box mt--06" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">Reisekosten innerhalb Prag und bis 30 Km entfernung inbegriffen</span>"}>
              </Text>

              <Text className="text-box mt--06" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">36 Fotografien im Format 13 x 18 cm im Album</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Porträt fotografieren 1 Person / 3 Std."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"anim":"2","animS":"4","maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Fotografieren / 1 Std."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--border4 js-anim  --anim2 --anim-s4 mt--16 pb--50 pl--40 pr--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} border={"4"}>
              
              <Subtitle className="subtitle-box fs--43" style={{"maxWidth":451}} content={"Porträt fotografieren 1 Person / 3 Std."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":468}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"1zv03r3aiu9i"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim2 --anim-s4 pb--30 pt--30" anim={"2"} name={"76qrhkfotrr"} animS={"4"}>
          
          <ColumnWrap className="column__flex el--2" style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--43 pl--0 pr--0" content={"Weiteres ..."}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":550}}>
              
              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"1 Std. Aufenthallt im Atelier"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"1 Std. Aufenthallt im Atelier"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Hochzeitsalbum"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Transport (1 km)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"1 Std. Aufenthallt im Atelier"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--0 mt--0 pb--0 pl--0 pt--0">
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Hochzeitsalbum"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold;\">17€</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--80 pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--2 pb--30 pt--25 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Schreiben Sie uns ...</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Webproduktion stránek</a></span>"}>
              </Text>

              <Subtitle className="subtitle-box lh--16 mb--40" content={"<span style='color: var(--color-dominant)'>Ivan Kräuslich<br>+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Subtitle>

              <Image className="--left pb--06 pl--06 pr--06 pt--06" alt={""} src={"https://cdn.swbpg.com/o/11591/cac5c902f16a47b7b7aea31fabd7fc79.svg"} svg={false} href={"mailto:[[UNIemail]]"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}